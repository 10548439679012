.content {
    min-height: calc(100vh - 100px);
    background-color: rgba(255, 255, 255, 0.97);
}
.heading{
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
}
.a{
    font-weight: 500;
    margin-left: 25px;
}
.container{

    max-width: 1000px;
    margin:0 auto;
}
.isactive{
    color: #152637;
    border-bottom:1px solid #465986;
}